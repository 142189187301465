import React from 'react'
import './onmo.css';
import Navbar2 from '../../components/navbar2/Navbar2';
import Footer from '../footer/Footer'
import arrowdiagostraight from '../../assets/arrowdiagostraight.svg'

import zaapislide1  from '../../assets/onmo/zaapi_SLIDE1.jpg';
import zaapislide2 from '../../assets/onmo/zaapi_SLIDE2.jpg' 
import zaapislide3  from '../../assets/onmo/zaapi_SLIDE3.jpg';





const Toastcoin = () => {
    return (


<div className="oraj__toastcoin-content" id="onmo">

<Navbar2 />


<div className="slide1container">

<img src={zaapislide1} alt="zaapislide3"
   style={{

    width: "100%",
    left: "100%",
    top: "100%",
    height: "10%",
    objectFit: "contain",


  }}

/>    
</div>

<div className="container">





<div className="slide4container">
<div className="zaapislide4">



<img src={zaapislide2} alt="zaapislide3"
   style={{

    width: "100%",
    left: "100%",
    top: "100%",
    height: "10%",
    objectFit: "contain",


  }}

/>       


</div>
</div>





<div className="slide4container">
<div className="zaapislide4"


>
<img src={zaapislide3} alt="zaapislide4"
   style={{

    width: "100%",
    left: "100%",
    top: "100%",
    height: "10%",
    objectFit: "contain",


  }}

/>     

</div>
</div>  






</div>




            

              <div className='backtopbox'>
              <a href="#onmo">
            <button className="backtop">
            <p className='Button2'>Back to top</p><img src={arrowdiagostraight} alt="arrowdiagostraight" />
            </button>
            </a>

            </div>
            <Footer /> 



            </div>

    )
  }
  
  export default Toastcoin