import React from 'react'
import './landing.css';

import Toolbar from '../../components/toolbar/Toolbar';
import Bottom from '../../components/bottom/Bottom'
import herovideo2560 from '../../assets/herovideo2560.mp4'



const Landing = () => {
  return (

    <div className='Landing_Main'>
      <div className='Textbox'>
        <h3 className='H3'>I’m a montreal-based designer specialized in UX/UI, Motion Design and Branding.</h3>
        <h1 className='H1'>👋 I’m Karim ― a ‎ <span>Product Designer</span>‎ currently @Touchtunes</h1>
      </div>

      <div className='Safaribox'>
        <Toolbar />
        <div className='Cover'>
        <video className="Video" loop autoPlay playsInline muted autobuffer>
          <source src={herovideo2560} type="video/mp4" /> </video>
          </div>

        <Bottom />

        
      </div>

    </div>

    
  )
}



export default Landing