import React from 'react';
import './bottom.css';
import Socials from '../socials/Socials'

import mouse2560 from '../../assets/mouse2560.svg'
import mouse1512 from '../../assets/mouse1512.svg'






const Bottom = () => {

    return (

        <div className='Bottom_Main'>
            <div className='Scroll'>
                <img src={mouse2560} alt="mouse2560" className='mouse2560'/>
                <img src={mouse1512} alt="mouse1512" className='mouse1512'/>
                <p className='Button2'>Scroll to see more</p>
            </div>
            <div className='ShowSocials'>
            <Socials />
            </div>
        </div>

        )
    }
    
    export default Bottom