import React from 'react';
import './socials.css';
import linkedin2560 from '../../assets/linkedin2560.svg'
import linkedin1512 from '../../assets/linkedin1512.svg'
import instagram2560 from '../../assets/instagram2560.svg'
import instagram1512 from '../../assets/instagram1512.svg'






const Socials = () => {

    return (

        <div className='Socials_Main'>
            <a href="https://www.linkedin.com/in/karim-dari/" target="_blank" rel="noreferrer">
            <img src={linkedin2560} alt="linkedin2560" className='linkedin2560'/>
            </a>
            <a href="https://www.linkedin.com/in/karim-dari/" target="_blank" rel="noreferrer">
            <img src={linkedin1512} alt="linkedin1512" className='linkedin1512'/>
            </a>
            <a href="https://www.instagram.com/kadstud.io/" target="_blank" rel="noreferrer">
            <img src={instagram2560} alt="instagram2560" className='instagram2560'/>
            </a>
            <a href="https://www.instagram.com/kadstud.io/" target="_blank" rel="noreferrer">
            <img src={instagram1512} alt="instagram1512" className='instagram1512'/>
            </a>


        </div>

        )
    }
    
    export default Socials