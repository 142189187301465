
import Navbar from '../../components/navbar/Navbar';
import Landing from '../landing/Landing';
import Projects from '../projects/Projects';
import About from '../about/About';
import Footer from '../footer/Footer';





const Homepage = () => {
    return (
        <div>
                <Navbar />
                <Landing />
                <Projects />
                <About />
                <Footer />

    </div>
    )
  }
  
  export default Homepage