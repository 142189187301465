import React from 'react'

import './footer.css';

import logo2560 from '../../assets/logo2560.svg'
import logo1512 from '../../assets/logo1512.svg'
import Socials from '../../components/socials/Socials'



const Footer = () => {
  return (
    <div className='Footer_Main'>
      <div className='Top'>
      <a href='/'><img src={logo2560} alt="logo2560" className='logo2560'/>
        <img src={logo1512} alt="logo1512" className='logo1512'/></a>
        <button className='Link'><p className='Button'><a href="mailto:karimdari36@gmail.com" className='a'>karimdari36@gmail.com</a></p></button>
      </div>

      <div className='Bottom'>
        <p className='Body1'>© Karim Dari 2024</p>
        <Socials />
      </div>
      
    </div>
  )
}

export default Footer