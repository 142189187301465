import React from 'react'
import './projects.css';

import layer2560 from '../../assets/layer2560.svg'
import layer1920 from '../../assets/layer1920.svg'
import layer444 from '../../assets/layer444.svg'

import security2560 from '../../assets/security2560.svg'

import arrow2560 from '../../assets/arrow2560.svg'
import arrow444 from '../../assets/arrow444.svg'





const Projects = () => {
    return (
        <div className='Projects_Main'>
            <div className='Title' >
                <img src={layer2560} alt="layer2560" className='layer2560'/>
                <img src={layer1920} alt="layer1920" className='layer1920'/>
                <img src={layer444} alt="layer444" className='layer444'/>
                <p className='H1' id='Projects'>Projects</p>
            </div>

            <a href="/roamr" className='a'>
                <div className='Project'>
                    <div className='Roamr'>
                        <div className='Tag'><p className='Overline'>2024 - PASSION PROJECT</p></div>
                    </div>
                    <div className='Textbox'>
                        <div className='Title'>
                            <p className='H2'>Roamr</p>
                            <img src={arrow2560} alt="arrow2560" className='arrow2560'/>
                            <img src={arrow444} alt="arrow444" className='arrow444'/>
                        </div>
                        <div className='Secondarytext'>
                        <p className='Body1'>Roamr is an app redefining the event planning and attendance experience</p>
                        </div>
                    </div>
                </div>
                </a>

                <a href="https://www.touchtunes.com/" target="_blank" rel="noreferrer" className='a'>
                <div className='Project'>
                    <div className='Touchtunes'>
                        <div className='Tag'><p className='Overline'>2023/2024 - EMPLOYED</p></div>
                    </div>
                    <div className='Textbox'>
                        <div className='Title'>
                            <p className='H2'>Touchtunes</p>
                            <img src={arrow2560} alt="arrow2560" className='arrow2560'/>
                            <img src={arrow444} alt="arrow444" className='arrow444'/>
                        </div>
                        <div className='Secondarytext'>
                        <p className='Body1'>Working as a full-time product designer for the new Touchtunes mobile app</p>
                        </div>
                    </div>
                </div>
                </a>


{/* 


            <a href="/onmo" className='a'>
                <div className='Project'>
                    <div className='Onmo'>
                        <div className='Tag'><p className='Overline'>2022 - EMPLOYED</p></div>
                    </div>
                    <div className='Textbox'>
                        <div className='Title'>
                            <p className='H2'>Onmo</p>
                            <img src={arrow2560} alt="arrow2560" className='arrow2560'/>
                            <img src={arrow444} alt="arrow444" className='arrow444'/>
                        </div>
                        <div className='Secondarytext'>
                        <p className='Body1'>Working as a full-time UX/UI Designer for the Onmo mobile app</p>
                        </div>
                    </div>
                </div>
                </a>


            
            <a href="/toastcoin" className='a'>
                <div className='Project'>
                    <div className='Toastcoin'>
                        <div className='Tag'><p className='Overline'>PASSION PROJECT</p></div>
                    </div>
                    <div className='Textbox'>
                        <div className='Title'>
                            <p className='H2'>Toastcoin</p>
                            <img src={arrow2560} alt="arrow2560" className='arrow2560'/>
                            <img src={arrow444} alt="arrow444" className='arrow444'/>
                        </div>
                        <p className='Body1'>A cryptocurrency marketing & website project</p>
                    </div>
                </div>
                </a>

            
                <a href="/muspace" className='a'>
                <div className='Project'>
                    <div className='Muspace'>
                        <div className='Tag'><p className='Overline'>PASSION PROJECT</p></div>
                    </div>
                    <div className='Textbox'>
                        <div className='Title'>
                            <p className='H2'>Muspace</p>
                            <img src={arrow2560} alt="arrow2560" className='arrow2560'/>
                            <img src={arrow444} alt="arrow444" className='arrow444'/>
                        </div>
                        <p className='Body1'>Working as a full-time product designer for the new Touchtunes mobile app</p>
                    </div>
                </div>
                </a>

*/}



        </div>
           


    )
  }
  
  
  export default Projects