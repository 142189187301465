import React from 'react'
import './roamr.css';
import Navbar2 from '../../components/navbar2/Navbar2';
import Footer from '../footer/Footer'

import logo_video from '../../assets/roamr/logo_video.mp4'
import about_video from '../../assets/roamr/about_video.mp4'
import map_video from '../../assets/roamr/map_video.mp4'
import social2_video from '../../assets/roamr/social2_video.mp4'
import search_video from '../../assets/roamr/search_video.mp4'









const Roamr = () => {
    return (


<div className="Roamr_Main" id="roamr">

<Navbar2 />

      <div className="Roamr_Section">

        
      <div className='Sectionbox'>

          <div className='Textbox'>
            <p className='H1'>Roamr</p>
            <p className='H3'>Passion project ― Redefining Event Exploration</p>
          </div>
      
          <div className='Row'>
            <div className='video_container'>
            <video loop autoPlay playsInline="true" disablePictureInPicture="true" muted autobuffer className="video_inside">
              <source src={logo_video} type="video/mp4"/>
            </video>
            </div>
            <div className='Iphonelogoimg'></div>
          </div>

          </div>

          <div className='Sectionbox'>
            <div className='Textbox'>
              <p className='H2'>Overview</p>
              <div className='Paragraph'>
                <p className='H3'>Roamr redefines the event planning and attendance experience with an interactive map and personalized recommendations of events near you.</p>
                <p className='H3'>It’s not just an app; it's a companion for those seeking to make the most of their city's offerings.</p>
              </div>
            </div>

            <div className='Textrowbox'>
              <div className='Textbox'>
                <p className='H2'>Scope</p>
                <p className='H3'>Mobile Application, Branding, Promotional Videos</p>
              </div>          
              <div className='Textbox'>
                <p className='H2'>Tools</p>
                <p className='H3'>Figma, Adobe Illustrator, Adobe After Effects</p>
              </div>
            </div>
          </div>


          <div className='video_container'>
            <video loop autoPlay playsInline="true" disablePictureInPicture="true" muted autobuffer className="video_inside">
              <source src={about_video} type="video/mp4"/>
            </video>
          </div>


          <div className='Sectionbox'>
            <div className='Textbox'>
                  <p className='H2'>The Event SuperApp</p>
                  <p className='H3'>Elevate your event game with Roamr, where every feature is designed to make connecting, planning, and sharing experiences a breeze. Welcome to the future of event discovery and organization—all in the palm of your hand.</p>
            </div>  
            <div className='Row'>
              <div className='Superapp1'></div>
              <div className='Superapp2'></div>
            </div>
          </div>


            <div className='Sectionbox'>
              <div className='Textbox'>
                    <p className='H2'>Interactive map experience</p>
                    <p className='H3'>Immerse yourself in the heart of your community. Whether it's a concert downtown or a pop-up market in your neighborhood, the map provides a dynamic way to explore the vibrant event landscape of your city.</p>
              </div>  
              <div className='video_container'>
              <video loop autoPlay playsInline="true" disablePictureInPicture="true" muted autobuffer className="video_inside">
                <source src={map_video} type="video/mp4"/>
              </video>
              </div>
            </div>


          <div className='Sectionbox'>
            <div className='Textbox'>
                  <p className='H2'>Connect with your city</p>
                  <p className='H3'>From a customized feed, to following and messaging other users, Roamr offers an unmatched social experience when it comes to creating or sharing events with friends.</p>
            </div>  
            <div className='Row'>
              <div className='Social1'></div>
              <div className='video_container'>
              <video loop autoPlay playsInline="true" disablePictureInPicture="true" muted autobuffer className="video_inside">
                <source src={social2_video} type="video/mp4"/>
              </video>
              </div>
            </div>
            <div className='Social3'></div>
          </div>

          <div className='Sectionbox'>
              <div className='Textbox'>
                    <p className='H2'>Find anything, anywhere, anytime</p>
                    <p className='H3'>Roamr’s powerful search tool allows you to find events and users instantly. Our algorithm tailors the results based on your preferences.</p>
              </div>  
              <div className='video_container'>
              <video loop autoPlay playsInline="true" disablePictureInPicture="true" muted autobuffer className="video_inside">
                <source src={search_video} type="video/mp4"/>
              </video>
              </div>
            </div>




      </div>

<Footer />


</div>



    )
  }
  
  export default Roamr


    

              