import React from 'react';
import './navbar2.css';
import logo2560 from '../../assets/logo2560.svg'
import logo1512 from '../../assets/logo1512.svg'
import backbtn2560 from '../../assets/backbtn2560.svg'
import backbtn444 from '../../assets/backbtn444.svg'


const Navbar2 = () => {

  return (

    <div className='Navbar2_Main'>
      <a href='/'>
          <button className='WButton'>
          <img src={backbtn2560} alt="backbtn2560" className='backbtn2560'/>
            <img src={backbtn444} alt="backbtn444" className='backbtn444'/>
            <h1 className='Button2'>Back</h1>
          </button>
        </a>
    </div>
  )
}

export default Navbar2