import React from 'react'
import './toastcoin.css';
import Footer from '../../containers/footer/Footer'
import Navbar2 from '../../components/navbar2/Navbar2';
import arrowdiagostraight from '../../assets/arrowdiagostraight.svg'

import zaapislide1  from '../../assets/toastcoin/zaapi_SLIDE1.mp4';
import zaapislide2 from '../../assets/toastcoin/zaapi_SLIDE2.jpg' 
import zaapislide3  from '../../assets/toastcoin/zaapi_SLIDE3.jpg';
import zaapislide4 from '../../assets/toastcoin/zaapi_SLIDE4.jpg'
import zaapislide5 from '../../assets/toastcoin/zaapi_SLIDE5.mp4'





const Toastcoin = () => {
    return (


<div className="oraj__toastcoin-content" id="toastcoin">

<Navbar2 />

<div className="slide1container">

<video loop autoPlay playsInline="true" disablePictureInPicture="true" muted autobuffer className="zaapislide1"
style={{
    width: "100.1%",
    height: "100%",
    zIndex: "1",
    position: "relative",
    objectFit: "fill",



  }}
>
    
<source src={zaapislide1} type="video/mp4"/>
</video>
</div>


<div className="container">


<div className="slide4container">
<div className="zaapislide4">



<img src={zaapislide2} alt="zaapislide2"
   style={{

    width: "100%",
    left: "100%",
    top: "100%",
    height: "10%",
    objectFit: "contain",


  }}

/>       


</div>
</div>


<div className="slide4container">
<div className="zaapislide4">



<img src={zaapislide3} alt="zaapislide3"
   style={{

    width: "100%",
    left: "100%",
    top: "100%",
    height: "10%",
    objectFit: "contain",


  }}

/>       


</div>
</div>





<div className="slide4container">
<div className="zaapislide4"


>
<img src={zaapislide4} alt="zaapislide4"
   style={{

    width: "100%",
    left: "100%",
    top: "100%",
    height: "10%",
    objectFit: "contain",


  }}

/>     

</div>
</div>  

<div className="slide5container">
<video loop autoPlay playsinline="true"
disablePictureInPicture="true"
muted autobuffer className="zaapislide5"
style={{
    width: "100%",
    left: "100%",
    top: "100%",
    height: "100%",
    objectFit: "contain",


  }}
>
    
<source src={zaapislide5} type="video/mp4"/>
</video>

</div>





</div>




            

<div className='backtopbox'>
              <a href="#toastcoin">
            <button className="backtop">
            <p className='Button2'>Back to top</p><img src={arrowdiagostraight} alt="arrowdiagostraight" />
            </button>
            </a>

            </div>
            <Footer /> 



            </div>

    )
  }
  
  export default Toastcoin