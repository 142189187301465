import React from 'react';
import './navbar.css';
import logo2560 from '../../assets/logo2560.svg'
import logo1512 from '../../assets/logo1512.svg'
import arrowbtn2560 from '../../assets/arrowbtn2560.svg'
import arrowbtn444 from '../../assets/arrowbtn444.svg'


const Navbar = () => {

  return (

    <div className='Navbar_Main' data-scroll>
      <a href='/'><img src={logo2560} alt="logo2560" className='logo2560'/>
      <img src={logo1512} alt="logo1512" className='logo1512'/></a>
      <a href="https://drive.google.com/file/d/12BAC4u-2gfqRfoDyL18T-Rx47CgdUpsW/view" target="_blank" rel="noreferrer">
          <button className='WButton'>
            <h1 className='Button2'>Resume</h1>
            <img src={arrowbtn2560} alt="arrowbtn2560" className='arrowbtn2560'/>
            <img src={arrowbtn444} alt="arrowbtn444" className='arrowbtn444'/>
          </button>
        </a>
    </div>
  )
}

export default Navbar