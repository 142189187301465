import React from 'react'
import './about.css';

import Toolbar from '../../components/toolbar/Toolbar';
import Bottom from '../../components/bottom/Bottom'


import user2560 from '../../assets/user2560.svg'
import user1920 from '../../assets/user1920.svg'
import user444 from '../../assets/user444.svg'

import people2560 from '../../assets/people2560.svg'
import people1512 from '../../assets/people1512.svg'

import heart2560 from '../../assets/heart2560.svg'
import heart1512 from '../../assets/heart1512.svg'

import document2560 from '../../assets/document2560.svg'
import document1512 from '../../assets/document1512.svg'

import memoji_video from '../../assets/memoji_video.mp4'






const About = () => {
  return (

    <div className='Aboutme_Main'>
      <div className='Titlebox'>
        <img src={user2560} alt="user2560" className='user2560'/>
        <img src={user1920} alt="user1920" className='user1920'/>
        <img src={user444} alt="user444" className='user444'/>
        <p className='H1' id='About'>About Me</p>
      </div>
      <div className='Safaribox'>
        <Toolbar />
        <div className='Profile'>
          <div className='Textbox'>
            <p className='H2'>Karim Dari</p>
            <p className='H3'>Branding, product & motion design</p>
          </div>
          <video loop autoPlay playsInline="true" disablePictureInPicture="true" muted autobuffer className="Profilepic">
              <source src={memoji_video} type="video/mp4"/>
          </video>
        </div>
        <Bottom />

      </div>

      <div className='Infobox'>
          <div className='Iconbox'>
            <img src={people2560} alt="people2560" className='people2560'/>
            <img src={people1512} alt="people1512" className='people1512'/>
          </div>
          <div className='Textbox2'>
            <p className='H2'>Team-driven</p>
            <p className='Body1'>Teamwork to me is using each other’s qualities to build a complete product.</p>
          </div>
        </div>

        <div className='Infobox'>
          <div className='Iconbox'>
            <img src={heart2560} alt="heart2560" className='heart2560'/>
            <img src={heart1512} alt="heart1512" className='heart1512'/>
          </div>
          <div className='Textbox2'>
            <p className='H2'>Designer at heart</p>
            <p className='Body1'>I care about every project i work on, every user i impact and every pixel i place on a screen.</p>
          </div>
        </div>

        <div className='Infobox'>
          <div className='Iconbox'>
            <img src={document2560} alt="document2560" className='document2560'/>
            <img src={document1512} alt="document1512" className='document1512'/>
          </div>
          <div className='Textbox2'>
            <p className='H2'>Documentation</p>
            <p className='Body1'>Helping developers through user flows, feature logic, documentation &  design systems is very important to me.</p>
          </div>
        </div>




      {/* <div className='Row2'> 
        <div className='Leftbox'>
          <div className='Infobox2'>
            <div className='Tag2'>
              <p className='Overline'>RESUME</p>
            </div>
            <p className='H3'>I’ve been a designer for the past 10 years. Starting on the adobe suite, to print design tools, to figma & UX/UI. Also, I’ve been working in the design industry for the past 3 years and as a product designer for the past 2 years.</p>
          </div>
          <div className='Infobox2'>
            <div className='Tag2'>
              <p className='Overline'>FUN FACTS</p>
            </div>
            <p className='H3'>I’m born and raised in Montreal, Quebec. I speak English & French. A few of my hobbies are jogging, snowboarding, cooking and occasionally gaming.</p>
          </div>
        </div>

        <div className='Rightbox'>


          <div className='Tag2'>
            <p className='Overline'>Experience</p>
          </div>


          <div className='Column'>
            <div className='Jobbox'>
              <div className='Titlebox2'>
                <div className='Dot'></div>
                <p className='H2'>Product designer @Touchtunes</p>
              </div>
              <div className='Description'>
                <div className='Linebox'>
                  <div className='Line'></div>
                </div>
                <div className='Textbox3'>
                  <p className='Button2'>May 2023 - Present</p>
                  <ul>
                    <li className='Body1'>Working on the UX & UI of the Touchtunes application.</li>
                    <li className='Body1'>User testing new & existing features, prototyping.</li>
                    <li className='Body1'>Animating different assets & features.</li>

                  </ul>
                </div>
              </div>
            </div>
          </div>



          <div className='Column'>
            <div className='Jobbox'>
              <div className='Titlebox2'>
                <div className='Dot'></div>
                <p className='H2'>UX/UI designer @Onmo/Rob0</p>
              </div>
              <div className='Description'>
                <div className='Linebox'>
                  <div className='Line'></div>
                </div>
                <div className='Textbox3'>
                  <p className='Button2'>March 2022 - May 2023</p>
                  <ul>
                    <li className='Body1'>Working on the UX/UI of the ONMO platform.</li>
                    <li className='Body1'>Building the entire design system for the ONMO App..</li>
                    <li className='Body1'>Creating user flows in figma and communicating design with devs and QA team.</li>
                    <li className='Body1'>Ideating, researching and designing new or existing features, etc...</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div className='Column'>
            <div className='Jobbox'>
              <div className='Titlebox2'>
                <div className='Dot'></div>
                <p className='H2'>Graphic Designer @ Adm.A</p>
              </div>
              <div className='Description'>
                <div className='Linebox'>
                  <div className='Line'></div>
                </div>
                <div className='Textbox3'>
                  <p className='Button2'>June 2019 - January 2021</p>
                  <ul>
                    <li className='Body1'>Designer for the Quebec Order of Administrators (Adm.A).</li>
                    <li className='Body1'>Designing promotional flyers. logos. web material, print material & annual reports.</li>
                    <li className='Body1'>Taking care of the mailing list using mailchimp.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


        </div>


      </div>
      */}
    </div>

  )
}

export default About