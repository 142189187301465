import React, { useState, useEffect,} from 'react'
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom"; 

import { Homepage, Toastcoin, Muspace, Onmo, Roamr } from './containers';
import { Navbar } from './components';

import { Helmet } from "react-helmet";
import './App.css';
import './fonts/WhyteInktrap-Heavy.woff';
import './fonts/WhyteInktrap-Heavy.woff2';

import FullAnim from './assets/FullAnim.json'

import Lottie from 'lottie-react'














const App = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, []);

    



    return (



        <div className="App" >

            <Helmet>
                <meta charSet="utf-8" />
                <title>Karim Dari | UX/UI Designer</title>
                <link rel="canonical" href="http://karimdari.com/" />
                <meta name="og:site_name" content="Karim Dari | UX/UI Designer" />
                <meta name="description" content="Hi! Nice to meet you, my name is Karim Dari. I'm a UX/UI Designer based in Montreal" />
                <meta name="keywords" content="Karim Dari, UX/UI, UX, UI, Design, Designer, Montreal, Motion, Branding, custom web design services, web design services, web design firm, web design studio, web design boutique, web design solutions, [Wordpress] web design services, mobile website design services / mobile website design services (less search volume), responsive web design services / responsive website design services (less search volume), website redesign services" />
            </Helmet>

            {
            loading ? 

            <div className='loader-container'>
                <Lottie className='FullAnim' loop={false} animationData={FullAnim} />
            </div>



:



            <BrowserRouter>







            
            <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/onmo" element={<Onmo />} />
            <Route path="/muspace" element={<Muspace />} />
            <Route path="/toastcoin" element={<Toastcoin />} />
            <Route path="/roamr" element={<Roamr />} />



            </Routes>




            
        </BrowserRouter>

}


        </div>



    )
    
}

export default App
  
