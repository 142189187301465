import React from 'react';
import './toolbar.css';
import plus2560 from '../../assets/plus2560.svg'
import tabs2560 from '../../assets/tabs2560.svg'





const Toolbar = () => {

    return (

        <div className='Toolbar_Main'>
            <div className='Left'>
                <div className='Close'></div>
                <div className='Minimize'></div>
                <div className='Maximize'></div>

            </div>
            <div className='Right'>
            <img src={plus2560} alt="plus2560" className='plus2560'/>
            <img src={tabs2560} alt="tabs2560" className='tabs2560'/>
            </div>


        </div>

        )
    }
    
    export default Toolbar